import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { Carrera } from '../../models/observacion';
import { ActivatedRoute, Params } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-card-overlay2',
  templateUrl: './card-overlay2.component.html',
  styleUrls: ['./card-overlay2.component.scss']
})
export class CardOverlay2Component implements OnInit {
  @Input() carrera: Carrera
  @Input() borde: boolean = false
  language: any
  @Input() detalleCurso: boolean = true
  constructor(private aRouter: ActivatedRoute,private servicioGeneral:GeneralService) { 
    this.aRouter.params.forEach((params: Params) => {
      this.language = params['language'];
    });
  }
  redirect(c) {
    if (c.route) {
      window.location.href = this.language + c.route
    } else if (c.linkCareer) {
      window.location.href = this.language + c.linkCareer
    }

  }
  createLink(item) {
    let url = environment.apiUrl + item
    return url
  }

  formatearFecha(value: any): any {
    if (value.inicio && value.inicio.date) {
      const date = new Date(value.inicio.date);
      const monthNames = [
        "enero", "febrero", "marzo",
        "abril", "mayo", "junio", "julio",
        "agosto", "septiembre", "octubre",
        "noviembre", "diciembre"
      ];
      const day = date.getDate();
      const monthIndex = date.getMonth();
      const monthName = monthNames[monthIndex];
      return `${day} de ${monthName} ${date.getFullYear()}`;
    } else {
      return 'CONSULTAR'
    }

  }
  ngOnInit(): void {
  }
  
  cortarTitulo(titulo: string): string {
    let maxLength= 100;

    if (!titulo) return '';

    if (titulo.length <= maxLength) {
      return titulo;
    } else {
      return titulo.substring(0, maxLength) + '...';
    }
  }
}

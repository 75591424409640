<div class="ingreso-container">
  <section class="subheader sub-video doble-m">
    <div class="video-overlay"></div>
    <!-- <img onerror="this.src='./assets/img/default.png'" *ngIf="!isMob && path" class="img-title w-100 desk img-desk" [src]="path">
      <img onerror="this.src='./assets/img/default.png'" *ngIf="isMob && pathMob" class="img-title w-100 mob img-mob" [src]="pathMob">
  -->
    <video #videoPlayer autoplay muted playsinline loop *ngIf="!isMob" class="img-title w-100 img-desk">
      <source [src]="apiUrl+'/public/filters/careerPhoto/public/Portada%20Grado%201300_525.mp4'"
      
        type="video/mp4">
    </video>
    <video #videoPlayerMob autoplay muted playsinline loop *ngIf="isMob" class="img-title w-100 img-desk">
      <source [src]="apiUrl+'/public/filters/careerPhoto/public/Portada%20Grado%20835_796.mp4'"
        type="video/mp4">
    </video>
    <!--OVC-bqQ8CVE-->
    <iframe *ngIf='link' allow="loop; autoplay; encrypted-media" allowfullscreen="" frameborder="0"
      [src]="getVideoIframe(link)">
    </iframe>
  </section>


  <section id='elegi-tu-carrera' class="buscador">
    <div class="row">
      <div class="col-lg-12">
        <div class="container-carousel-header w-100 d-flex justify-content-center align-items-center"
          style="margin-bottom: 50px ">
          <ng-container *ngFor="let boton of botones">
            <div class="carousel-cell-header  container-boton">
              <a [href]="boton.link" [style.background]="boton.background" class="boton pointer">
                <span class="nunito-regular">
                  {{boton.label}}
                </span>
              </a>
            </div>
          </ng-container>
        </div>
      </div>
    </div>


    <div class="">
      <div class="row">
        <div class="col-lg-12">
          <app-buscador-carreras></app-buscador-carreras>
        </div>
      </div>
    </div>
  </section>
  <!-- <section class="logsec1">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <app-todas-las-carreras></app-todas-las-carreras>
        </div>
      </div>
    </div>
  </section> -->
  <section id='nuestras-sedes'>
    <div class="">
      <div class="row">
        <div class="col-lg-12">
          <app-nuestras-sedes [title]="'NUESTRAS CARRERAS'"></app-nuestras-sedes>
        </div>
      </div>
    </div>
  </section>
  <section class="" *ngIf='benefitsButtons'>
    <app-icons *ngIf='benefitsButtons' [data]='benefitsButtons' [dataImages]="images"
      [dataImagesMobile]="imagesMobile"></app-icons>
    <!-- <div class="" style="width: 100%;">
      <div class="">
        <div class="">
        
        </div>
      </div>
    </div> -->
  </section>
  <section id='bienvenida' class="bienvenida">
    <app-bienvenida></app-bienvenida>
    <!-- <div class="">
      <div class="row">
        <div class="col-lg-12">
        
        </div>
      </div>
    </div> -->
  </section>


  <section id=''>
    <div class="">
      <div class="row">
        <div class="col-lg-12">
          <app-items-ingreso *ngIf='benefitsButtons' [data]='benefitsButtons'></app-items-ingreso>
        </div>
      </div>
    </div>
  </section>
</div>
<!-- <section class="logsec1">

  <app-formas-de-contacto></app-formas-de-contacto>

</section> -->
<!--<ng-container #componenteDinamico2></ng-container>
<ng-container #componenteDinamico3></ng-container>
<ng-container #componenteDinamico4></ng-container>
<ng-container #componenteDinamico5></ng-container>
<ng-container #componenteDinamico6></ng-container>
<ng-container #componenteDinamico7></ng-container>
<ng-container #componenteDinamico8></ng-container>
<ng-container #componenteDinamico9></ng-container>
<ng-container #componenteDinamico10></ng-container>
<ng-container #componenteDinamico11></ng-container>
<ng-container #componenteDinamico12></ng-container>-->
<div class="fondo-area" style="text-align: -webkit-center;   ">
    <div  class="" style="    justify-content: center;          ">
        <div id="areas"  class="posg-cont-c" *ngIf="!cursos">
            
            <app-card-area-conocimiento  [item]="item" [page]="'posgrado'"  *ngFor='let item of data;' >

            </app-card-area-conocimiento>
        </div>

        <div id="areas"  class="posg-cont-c" *ngIf="cursos">
            <app-card-area-conocimiento  [item]="item" [page]="'cursos'"  *ngFor='let item of  dataCursos.arriba;' >

            </app-card-area-conocimiento>

        
        </div>
        <div class="linea"  *ngIf="dataCursos && dataCursos.abajo && dataCursos.abajo.length>0"></div>

        <div id="areas"  class="posg-cont-c " *ngIf="cursos" >
            <app-card-area-conocimiento  [item]="item" [page]="'cursos'"  *ngFor='let item of  dataCursos.abajo;' ></app-card-area-conocimiento>

        </div>
    </div>
</div>
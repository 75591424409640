import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { Carrera } from 'src/app/shared/models/observacion';

@Component({
  selector: 'app-buscador-cursos',
  templateUrl: './buscador-cursos.component.html',
  styleUrls: ['./buscador-cursos.component.scss']
})
export class BuscadorCursosComponent  implements OnInit {
  meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  regiones: string[] = [];
  cursos: any[] = [];
  niveles: any[] = [];
  areas: any[] = [];
  modalidades: any[] = [];
  tematicas: any[] = [];
  resultados: any[] = [];
  buscador: FormGroup;
  valor: string;
  count: number;
  pages: any[] = [];
  numberActive = '1';
  pageSize = 15;
  spinner = true;
  filtro = false;
  language = "es"
  cursosAux: any[] = [];
  resultadosCompletos:  any[] = [];
  auxBorrar: any[] = []
  constructor(public route: ActivatedRoute, private servicesGeneral: GeneralService, private form: FormBuilder) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });
    this.buscador = this.form.group({
      tematica: ['',],
      sede: ['',],
      nivel: ['',],
      modalidad: ['',],
      palabras: ['',],
      area: ['',]
    });

    this.servicesGeneral.getCursos().subscribe(data => {
     
      this.resultados = [];
      this.cursos = [];
/*       this.areas = data.data[0].area; */
      this.regiones = data.data[0].region;
      data.data[0].careerTypeCourse.forEach(n => {
        if(n != 'Doctorado' ){
          this.niveles.push(n)
        }
      });
      
      this.modalidades = data.data[0].modalidad;
      this.spinner = false;    
      /* for (const area of data.data[0].areaIngreso) {
        const nuevaArea = {
          value: area.id,
          label: area.title
        };
        this.areas.push(nuevaArea);
      } */
      this.areas = data.data[0].areaIngreso;
     

      data.data[0].career.forEach(element => {
       
        if(element.inicio){
          let inicio = (element.inicio) ? this.getInicio(new Date(element.inicio.date).getMonth(), new Date(element.inicio.date).getFullYear()) : 'A definir';
          let title = element.title + ' | Inicio: ' + inicio;
          let date = (element.inicio) ? new Date(element.inicio.date) : null;
          let modalidad = element.modalidad
          this.cursos.push(new Carrera(element.id, title, element.linkCareer, date, modalidad))
        }else{
          this.cursos.push(new Carrera(element.id, element.title, element.linkCareer, element.modalidad))
        }
      });
      this.cursos.sort(function(a, b) {
        if (a.fecha instanceof Date && b.fecha instanceof Date) {
          return a.fecha.getTime() - b.fecha.getTime();
        } else {
          return 0;
        }
      });      this.cursosAux = this.cursos
      this.cambiarPagina(1);
     

      this.spinner = false;
    })
  }


  ngOnInit(): void {
  }


  filtrar(event) {
    //console.log("event1", event)
    this.spinner = true;
    //var tematica = this.buscador.get('tematica').value;
    var sede = this.buscador.get('sede').value;
    var nivel = this.buscador.get('nivel').value;
    var area = this.buscador.get('area')?.value;
    //console.log("area", area)
    var modalidad = this.buscador.get('modalidad').value;
    var palabras = this.buscador.get('palabras').value;
    var resultados = document.getElementById("resultados");
    //console.log(sede, nivel, modalidad, palabras)
    this.servicesGeneral.getCursos(sede, nivel, palabras, area).subscribe(data => {
      this.resultadosCompletos=[]
      let aux = [];
      this.resultados = [];
      this.cursosAux = [];
      if (sede == '' && nivel == ''  && modalidad == '' && palabras == '' && area == '') {
        this.resultados = [];
        this.filtro = false
        resultados.style.display = 'none';

      } else {
        this.filtro = true
        resultados.style.display = 'block';
        data.data[0].career.forEach(element => {
          if(element.inicio){
            let inicio = (element.inicio) ? this.getInicio(new Date(element.inicio.date).getMonth(), new Date(element.inicio.date).getFullYear()) : 'A definir';
            let title = element.title + ' | Inicio: ' + inicio;
            let date = (element.inicio) ? new Date(element.inicio.date) : null;
            let modalidad = element.modalidad
            this.resultadosCompletos.push(new Carrera(element.id, title, element.linkCareer, date, modalidad))
          }else{
            this.resultadosCompletos.push(new Carrera(element.id, element.title, element.linkCareer, element.modalidad))
          }
        });
        //this.resultadosCompletos.sort(function (a, b) { return (a.fecha && b.fecha) ? a.fecha.getTime() - b.fecha.getTime() : 0 });
        this.cambiarPagina(1)
      }
    })
  }
  cambiarPagina(page) {
    var modalidad = this.buscador.get('modalidad').value;
    //var palabra = this.buscador.get('palabras').value;
    this.pages = [];
    let resultadoFiltrado = this.filtros(modalidad);
    let cant = Math.ceil(resultadoFiltrado.length / this.pageSize)
    this.resultados = resultadoFiltrado.slice(((page - 1) * this.pageSize), (page) * this.pageSize)
    
    for (let i = 0; i < cant; i++) {
      this.pages.push(i + 1);
    }
    this.numberActive = page;
    page = (page == '...') ? (this.numberActive) : page;
    this.spinner = false;
  }
  filtros(modalidad: string): any[] {
    let listaFiltrada = this.resultadosCompletos;
    if (modalidad) {
      listaFiltrada = listaFiltrada.filter(r => this.processText(r.modalidad) == this.processText(modalidad))
    }

    listaFiltrada.sort((a, b) => {
      const fechaA = new Date(a.fecha);
      const fechaB = new Date(b.fecha);
  
      const difA = Math.abs(Math.ceil((fechaA.getTime() - new Date().getTime()) / (1000 * 3600 * 24)));
      const difB = Math.abs(Math.ceil((fechaB.getTime() - new Date().getTime()) / (1000 * 3600 * 24)));
  
      if (difA < difB) {
        return -1;
      } else if (difA > difB) {
        return 1;
      } else {
        return fechaA.getTime() - fechaB.getTime();
      }
    });


    return listaFiltrada;
  }
  getInicio(mes: number, año: number) {
    return this.meses[mes] + ' ' + año;
  }
  processText(palabra: string): string {
    if(palabra){
      palabra = palabra.trim();
      palabra = palabra.toLocaleLowerCase();
    }

    return palabra;
  }
  ngOnDestroy() {

  }
}

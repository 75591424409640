<div class="posgrados-container">
  <div class="w-100 position-relative">
    <div class="overlay"></div> <!-- Capa transparente -->

    <!-- <img onerror="this.src='./assets/img/default.png'" *ngIf='path' class="   w-100 desk " [src]="path">
        <img onerror="this.src='./assets/img/default.png'" *ngIf='pathMob' class=" img-mob  w-100 mob " [src]="pathMob"> -->
    <!--  <img *ngIf="isMob" src="../../../../../assets/img/posgradosMob.png" class=" w-100 img-mob" alt="">
        <img *ngIf="!isMob" src="../../../../../assets/img/portada-posgrados.png" class=" w-100 img-desk" alt=""> -->

    <video #videoPlayer autoplay muted playsinline loop *ngIf="!isMob" class=" w-100 img-desk">
      <source [src]="apiUrl + '/public/filters/careerPhoto/public/PORTADA%20POGRADO%20%201300-525.mp4'"
        type="video/mp4">
    </video>
    <video #videoPlayerMob autoplay muted playsinline loop *ngIf="isMob" class=" w-100 img-desk">
      <source [src]="apiUrl+'/public/filters/careerPhoto/public/PORTADA%20POSGRADO%20%20835-796.mp4'" type="video/mp4">
    </video>
    <!--OVC-bqQ8CVE-->
    <iframe *ngIf='link' allow="loop; autoplay; encrypted-media" allowfullscreen="" frameborder="0"
      [src]="getVideoIframe(link)">
    </iframe>

  </div>
  <section id='elegi-tu-carrera' class="buscador">
    <div class="row">
      <div class="col-lg-12">
        <div class="container-carousel-header w-100 d-flex justify-content-center align-items-center"
          style="margin-bottom: 50px ">
          <ng-container *ngFor="let boton of botones">
            <div class="carousel-cell-header  container-boton">
              <a [href]="boton.link" [style.background]="boton.background" class="boton-c pointer">
                <span class="nunito-regular">
                  {{boton.label}}
                </span>
              </a>
            </div>
          </ng-container>
        </div>
      </div>
    </div>


    <div class="">
      <div class="row">
        <div class="col-lg-12">
          <app-buscador-posgrados ></app-buscador-posgrados>
        </div>
      </div>
    </div>
  </section>
 
  <section class="">
    <app-areas-de-conocimiento></app-areas-de-conocimiento>
  </section>

  <section id='nuestras-sedes'>
    <div class="">
      <div class="row">
        <div class="col-lg-12">
          <app-nuestras-sedes [label]="'Conocé nuestras Carreras de Posgrado'" [title]="'NUESTRAS CARRERAS DE POSGRADO'" [backgroundColor]="'#414141'" [btnColor]="'#414141'"></app-nuestras-sedes>
        </div>
      </div>
    </div>
  </section>

  <section class="">

    <app-formas-de-contacto-dos [data]="'#c1b283'"></app-formas-de-contacto-dos>

  </section>
  <section class="">
    <app-icons [dataImages]="images" [dataImagesMobile]="imagesMobile" [ingreso]="false"></app-icons>
  </section>
  <!-- <section *ngIf="isMob" id='' >
        <div class="">
          <div class="row">
            <div class="col-lg-12">
              <app-items-ingreso *ngIf='benefitsButtons' [data]='benefitsButtons' [ingreso]="false"></app-items-ingreso>
            </div>
          </div>
        </div>
      </section> -->
  <!--<ng-container #componenteDinamico3></ng-container>
    <ng-container #componenteDinamico4></ng-container>
    <ng-container #componenteDinamico5></ng-container>
    <ng-container #componenteDinamico6></ng-container>
    <ng-container #componenteDinamico7></ng-container>
    <ng-container #componenteDinamico8></ng-container>
    <ng-container #componenteDinamico9></ng-container>
    <ng-container #componenteDinamico10></ng-container>
    <ng-container #componenteDinamico11></ng-container>
    <ng-container #componenteDinamico12></ng-container>-->
</div>
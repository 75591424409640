<div style="text-align: -webkit-center;">
    <div class="buscador-container">
        <h1 class="titulo-buscador quicksand-medium" *ngIf="!sede">Carreras de Posgrado </h1>
        <h5 class="subtitulo-buscador quicksand-medium" *ngIf="!sede">Encontrá toda la información sobre el posgrado que
            te interesa</h5>
        <h5 class="subtitulo-buscador quicksand-medium" *ngIf="sede">ENCONTRÁ TU CARRERA EN NUESTRA SEDE</h5>
        <form [formGroup]="buscador" class="form-group" id="busqueda"
            style='margin-bottom:0px;justify-content: space-between;'>

            <div class="container-responsive" #busqueda>
                <div class="input-container">
                    <input autofocus type="text" formControlName='palabras' (keyup)="filtrar(null)"
                        class="form-control-c " placeholder="Palabras clave"
                        style="border-radius: 0px !important;     padding-left: 10px;">
                    <img class="buscar" src="../../../../../assets/img/lupita-posgrado.png" alt="">
                </div>
                <div class="input-container">
                    <!-- <select id="nivel" formControlName="nivel" class="form-control" (change)="filtrar(null)"
                        style="border-radius: 0px !important;">
                        <option value=''>Nivel académico</option>
                        <option *ngFor='let item of niveles' [value]='item'>{{item}}</option>
                    </select> -->
                    <app-custom-select [options]="niveles" placeholder="Elegí el nivel"
                        (selectionChange)="updateFilter('nivel', $event)" [id]="'select-nivel'">
                    </app-custom-select>
                </div>
                <div class="input-container" *ngIf="!sede">
                    <!-- <select id="sedes" formControlName="sede" class="form-control " (change)="filtrar(null)"
                        style="border-radius: 0px !important;">
                        <option value=''>Sede</option>
                        <option class="option" *ngFor='let item of regiones' [value]='item.id'>{{item.title}}</option>
                    </select> -->
                    <app-custom-select [options]="regiones" placeholder="Elegí la sede"
                        (selectionChange)="updateFilter('sede', $event)" [id]="'select-sede'">
                    </app-custom-select>

                </div>
                <div class="input-container">
                    <!-- <select id="area" formControlName="area" class="form-control" (change)="filtrar(null)"
                    style="border-radius: 0px !important;">
                        <option value=''>Area</option>
                        <option *ngFor='let item of areas' [value]='item'>{{item}}</option>
                    </select> -->
                    <app-custom-select [options]="areas" placeholder="Elegí el área"
                        (selectionChange)="updateFilter('area', $event)" [id]="'select-area'">
                    </app-custom-select>
                </div>
                <div class="input-container" style="margin-right: 0% !important;">
                    <!-- <select id="modalidad" formControlName="modalidad" class="form-control" (change)="filtrar(null)"
                        style="border-radius: 0px !important;">
                        <option value=''>Modalidad</option>
                        <option *ngFor='let item of modalidades' [value]='item'>{{item}}</option>
                    </select> -->
                    <app-custom-select [options]="modalidades" placeholder="Modalidad"
                        (selectionChange)="updateFilter('modalidad', $event)" [id]="'select-modalidad'">
                    </app-custom-select>
                </div>



            </div>
            <!-- <div class='text-center'>
                <a (click)='filtrar()' class="btn btn-primary ml-2">Buscar</a>
    
            </div> -->


        </form>
    </div>

    <div *ngIf="sede" routerLink="/es/posgrados" class="breadcump">
        <span class="nunito-medium"> Posgrados | <span class="nunito-bold"> {{sede}}</span></span>
    </div>
    <div class="resultados card p-2" id="resultadosSwipe" #resultadosSwipe>
        <ul *ngIf='!spinner' style="padding-left: 0px !important;">
            <!-- <li class='mt-3 fs-21' *ngFor='let carrera of resultados'><a [href]="language +carrera.link"> {{carrera.nombre}} | {{carrera.modalidad}} </a></li> -->
            <div class="sede-container">
                <ng-container *ngFor='let carrera of resultados'>
                    <app-card-overlay [carrera]="carrera" [posgrado]="true"></app-card-overlay>
                    <!--  <div class="sede" (click)="redirect(carrera)">
                        <div class="sede-overlay">
                            <img *ngIf="carrera.pathMobile" [src]="carrera.pathMobile" alt="" class="img"
                                onerror="this.src='../../../../../assets/img/default.png'">
                            <img *ngIf="!carrera.pathMobile" src="../../../../../assets/img/default.png" alt="" class="img">
                            <div class="overlay-text">
                                <div>
                                    <p class="text text-small" style="display: flex; margin-bottom: 5px;">
                                        {{carrera.modalidad}}
                                        <ng-container *ngIf="carrera.modalidad == 'Presencial'">
                                            <img src="../../../../../assets/img/presencial-sm.png" alt=""
                                                class="icon-modalidad">
                                        </ng-container>

                                        <ng-container *ngIf="carrera.modalidad == 'Virtual'">
                                            <img src="../../../../../assets/img/online-sm.png" alt=""
                                                class="icon-modalidad">
                                        </ng-container>
                                        <ng-container *ngIf="carrera.modalidad == 'Híbrida'" style="display: flex;">
                                            <img src="../../../../../assets/img/presencial-sm.png" alt=""
                                                class="icon-modalidad">
                                            <img src="../../../../../assets/img/online-sm.png" alt=""
                                                class="icon-modalidad">
                                        </ng-container>
                                    </p>
                                    <p class="text dorado"
                                        style="background-color: #C1B283; padding: 8px;  margin-bottom: 5px;     width: fit-content;">
                                        {{carrera.nivel}}</p>
                                    <p  class="text text-small facultad" *ngIf="carrera.facultades && carrera.facultades.length >0">
                                        <span class="facultad-color"  [style.border-left]="'18px solid ' + carrera.color"></span>
                                        {{carrera.facultades[0]?.title}}</p>
                                </div>
                                <div>
                                    <p class="text titulo-card">{{carrera.title}}</p>
                                    <p class="text text-small">INICIO: {{ formatearFecha(carrera.inicio?.date) }}</p>
                                    <p class="text text-small">SEDE: {{carrera.sede}}</p>
                                    <a [href]="language +carrera.linkCareer">
                                        <p class="info">MÁS INFO >></p>
                                    </a>
                                </div>

                            </div>

                        </div>
                    </div> -->

                </ng-container>
            </div>


        </ul>
        <div class="d-flex justify-content-center" *ngIf='spinner && filtro'>
            <div class="spinner-grow loading" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow loading" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow loading" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- <div class="paginacion mt-3" *ngIf='!spinner && resultados.length > 0'>
            <div (click)='cambiarPagina(page)' *ngFor="let page of  pages" class="number "
                [ngClass]='(numberActive == page)?"active":""'>{{page}}</div>
        </div> -->
        <!--  <div class="paginacion mt-3" *ngIf='!spinner && resultados.length > 0'>
            <span class="range">{{ rangoResultados }}</span>
            <button (click)="cambiarPagina(1, false, true)" class="number arrow" [disabled]="!primerPaginaHabilitada()"
                style="border: none; background: none;">|< </button>
            <button (click)="cambiarPagina(numberActive - 1, false, true)" class="number arrow"
                [disabled]="!primerPaginaHabilitada()" style="border: none; background: none;">&lt;</button>

            <button (click)="cambiarPagina(numberActive + 1, false, true)" class="number arrow"
                [disabled]="!ultimaPaginaHabilitada()" style="border: none; background: none;">&gt;</button>
            <button (click)="cambiarPagina(pages.length, false, true)" class="number arrow" [disabled]="!ultimaPaginaHabilitada()"
                style="border: none; background: none;">>|</button>
        </div> -->
        <div class="paginacion mt-3" *ngIf='!spinner && resultados.length > 0  && pages.length >1'>
            <div *ngFor="let page of pages" class="number" [ngClass]='(numberActive == page) ? "active" : ""'
                (click)='cambiarPagina(page, false, true)'>{{page}}</div>

        </div>


        <div *ngIf=' resultados.length == 0 && !destacadas && !spinner && filtro' class='p-3 text-center'>
            <div class="alert alert-primary text-center" role="alert">
                No hay resultados
            </div>
        </div>
    </div>
</div>
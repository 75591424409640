import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';

@Component({
  selector: 'app-cursos-listado',
  templateUrl: './cursos-listado.component.html',
  styleUrls: ['./cursos-listado.component.scss']
})
export class CursosListadoComponent implements OnInit {
  datos: any;
  language: any;
  data: any;
  constructor(private services: GeneralService, private route: ActivatedRoute) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });
    
  }

  ngOnInit(): void {
  }
  headerSection:any={}
  ngAfterViewInit() {
    var url = location.href;
    var indexL = url.indexOf(this.language) + this.language.length + 1;
    var id = url.slice(indexL, url.length);
  
    this.services.getSimplepage(id, this.language).subscribe(data => {
      Object.keys(data.data[0].pages).forEach((key) => {
        var arrayValue = data.data[0].pages[key];
  
        this.services.getEntities(arrayValue[0].route + '&locale=' + this.language).subscribe(dataEntity => {
          if(arrayValue[0].entityType == "TextItem"){
            document.getElementById('text').innerHTML=dataEntity.data[0].title;

          }
          if(arrayValue[0].entityType == "HeaderSection"){
            this.headerSection=dataEntity.data[0].headerSection;
            
          }
        });
      });
    });
  }

}
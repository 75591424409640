import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { BotonHeader } from 'src/app/shared/models/botonHeader';
import { environment } from 'src/environments/environment';
import { CabeceraComponent } from '../../components/cabecera/cabecera.component';
import { CardsGeneralDosComponent } from '../../components/cards-general-dos/cards-general-dos.component';
import { CardsGeneralComponent } from '../../components/cards-general/cards-general.component';
import { CarrouselGeneralComponent } from '../../components/carrousel-general/carrousel-general.component';
import { CustomDiaryGeneralComponent } from '../../components/custom-diary-general/custom-diary-general.component';
import { CustomStatsGeneralComponent } from '../../components/custom-stats-general/custom-stats-general.component';
import { FormContactoComponent } from '../../components/form-contacto/form-contacto.component';
import { ListaDeLinksComponent } from '../../components/lista-de-links/lista-de-links.component';
import { PostComponent } from '../../components/post/post.component';
import { PrimeraFotoDestacadaComponent } from '../../components/primera-foto-destacada/primera-foto-destacada.component';
import { TextItemGeneralComponent } from '../../components/text-item-general/text-item-general.component';
import { VideoMasFormularioComponent } from '../../components/video-mas-formulario/video-mas-formulario.component';

@Component({
  selector: 'app-posgrados',
  templateUrl: './posgrados.component.html',
  styleUrls: ['./posgrados.component.scss']
})
export class PosgradosComponent implements OnInit {
  language: string;
  titleLink: string;
  breadcump: string;
  countFamily: number = 1;
  pathMob: any;
  singlepage: any;
  count = 0;
  data: any;
  arrayRowsSize: any;
  arrayRows: any[] = [];
  title: string;
  path: string;
  link: string;
  areas: any[] = [];
  botones: BotonHeader[] = [];

  @ViewChild('componenteDinamico1', { read: ViewContainerRef }) viewContainerRef1: ViewContainerRef;
  @ViewChild('componenteDinamico2', { read: ViewContainerRef }) viewContainerRef2: ViewContainerRef;
  @ViewChild('componenteDinamico3', { read: ViewContainerRef }) viewContainerRef3: ViewContainerRef;
  @ViewChild('componenteDinamico4', { read: ViewContainerRef }) viewContainerRef4: ViewContainerRef;
  @ViewChild('componenteDinamico5', { read: ViewContainerRef }) viewContainerRef5: ViewContainerRef;
  @ViewChild('componenteDinamico6', { read: ViewContainerRef }) viewContainerRef6: ViewContainerRef;
  @ViewChild('componenteDinamico7', { read: ViewContainerRef }) viewContainerRef7: ViewContainerRef;
  @ViewChild('componenteDinamico8', { read: ViewContainerRef }) viewContainerRef8: ViewContainerRef;
  @ViewChild('componenteDinamico9', { read: ViewContainerRef }) viewContainerRef9: ViewContainerRef;
  @ViewChild('componenteDinamico10', { read: ViewContainerRef }) viewContainerRef10: ViewContainerRef;
  @ViewChild('componenteDinamico11', { read: ViewContainerRef }) viewContainerRef11: ViewContainerRef;
  @ViewChild('componenteDinamico12', { read: ViewContainerRef }) viewContainerRef12: ViewContainerRef;
  viewContainerRefs: any[] = []
  rowsLength: any;
  rows: any[];
  cursosButtons: any[];
  rowsSize: any[] = []
  id_component: string;
  benefitsButtons: any[];
  isMob: boolean
  images = [
    { id: "banner-qs", url: 'assets/img/qs.jpg', redirect: '/es/noticias/la-facultad-de-ciencias-medicas-de-la-uca-por-primera-vez-en-el-qs-world-university-rankings-by-subject-2024-1' },
    { id: "banner-educacion-continua", url: 'assets/img/Educacion-continua.jpg', redirect: '/es/educacion-continua' },

  ];
  imagesMobile = [
    { id: "banner-qs", url: 'assets/img/QS-mobile.jpg', redirect: '/es/noticias/la-facultad-de-ciencias-medicas-de-la-uca-por-primera-vez-en-el-qs-world-university-rankings-by-subject-2024-1' },
    { id: "banner-educacion-continua", url: 'assets/img/Educacion-continua-mobile.jpg', redirect: '/es/educacion-continua' },
  ];
  apiUrl: any;
  @ViewChild('videoPlayer') videoPlayer: any;
  @ViewChild('videoPlayerMob') videoPlayerMob: any;
  posgrados: any[] = [];
  dataContacto = {
    "id": 1,
    "title": "Contactate con nosotros",
    "color_title": "#003a73",
    "subtitle": "o dejanos tus datos para recibir más información",
    "color_subtitle": "#ffffff",
    "background_color": "#c1b283",
    "link_whatsapp": "https://api.whatsapp.com/send?phone=5491130182402&app=facebook&entry_point=page_cta",
    "link_inst": "https://www.instagram.com/posgradosuca",
    "link_face": "https://www.facebook.com/UCAPosgrados",
    "mail_destination": "educacion.continua@gmail.com"
  }

  dataPortada =
    {
      "cover": {
        "id": 3,
        "main": true,
        "video_path": "/public/filters/careerPhoto/public/videos_portadas/PORTADA%20POGRADO%20%201300-525.mp4",
        "video_pathMobile": "/public/filters/careerPhoto/public/videos_portadas/PORTADA%20POSGRADO%20%20835-796.mp4" 
      },
      "coverButton": [
        {
          "id": 13,
          "label": "CHARLAS INFORMATIVAS",
          "link": "link-de-prueba",
          "background": "#2c5eaf",
          "width_button": "280",
          "estado": false
        }
      ]
    }
  constructor(private componentFactoryResolver: ComponentFactoryResolver, private _sanitizer: DomSanitizer, public router: ActivatedRoute, public meta: Meta,
    public route: Router,
    public services: GeneralService) {
    this.apiUrl = environment.apiUrl;
    this.id_component = this.router.snapshot.params['id'];
    let url = window.location.href;
    if (url.includes('#')) {
      let l = this.router.snapshot.params['language'];
      let index = url.indexOf("#")
      let palabra = url.slice((index + 1), url.length)
      window.location.href = "/" + l + "/busqueda/posgrados#" + palabra;
    }
    this.checkScreenWidth();
    this.services.getEntitylist('285', 'posgrados').subscribe(res => {
      res.data[0].entityList[0].entity.forEach(element => {
        element.class = element.title.replace(/ /g, "");
        this.posgrados.push(element);
      });

      this.posgrados.sort(this.SortArray);
    })
  }
  ngAfterViewInit() {
    if (this.videoPlayer) {
      this.videoPlayer.nativeElement.muted = true
      this.videoPlayer.nativeElement.play();
    }
    if (this.videoPlayerMob) {
      this.videoPlayerMob.nativeElement.muted = true
      this.videoPlayerMob.nativeElement.play();
    }
  }

  ngOnInit(): void {


    this.router.params.forEach((params: Params) => {
      this.language = params['language'];
    });


    /*this.id_component = window.location.href.replace(environment.frontUrl + '/' + this.language + '/posgrados/', '');
    if (this.id_component != window.location.href) {
      var element = document.getElementById(this.id_component)
      setTimeout(function () {
        element.scrollIntoView({ block: "start", behavior: "smooth" });
      }, 1000);
    }*/
    //borrar cuando sea dinamico
    this.services.getEntities('page/custom-entity/?id=43' + '&locale=' + this.language).subscribe(data1 => {
      let info = data1.data[0];
      if (info.cover) {
        this.benefitsButtons = info.benefitsButtons


      }
    });
    //

    this.services.getPosgradoEntities(this.language).subscribe(data => {

      this.rows = [];
      this.data = data['data'];
      this.rowsLength = this.data[0]['pagesLength'];
      // for (let i = 1; i < this.rowsLength + 1; i++) {
      //if (this.data[0][i][0]['status']) {
      //this.loadPageAt(i);
      //}
      //}
      this.loadPageAt(1);

    });


  }
  SortArray(x, y) {
    if (x.title < y.title) { return -1; }
    if (x.title > y.title) { return 1; }
    return 0;
  }
  private checkScreenWidth() {
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 768) {
        this.isMob = true
      } else {
        this.isMob = false
      }

    });
  }
  getVideoIframe(url) {
    var video, results;

    if (url === null) {
      return '';
    }
    results = url.match('[\\?&]v=([^&#]*)');
    video = (results === null) ? url : results[1];

    return this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + url + '?rel=0&amp;autoplay=1&amp;mute=1&amp;loop=1&amp;playlist=' + url + ';controls=0&amp;disablekb=1&amp;fs=0&amp;iv_load_policy=3&amp;modestbranding=1&amp;showinfo=0');
  }
  async loadPageAt(index: number) {
    if (this.data[0][index].length > 1) {

      this.services.getPosgradoEntity(this.data[0][index][0]['route'], this.language).subscribe(data1 => {
        this.definirComponentes(index, this.data[0][index][0], 8, data1)
        this.services.getPosgradoEntity(this.data[0][index][0]['route'], this.language).subscribe(data2 => {
          this.definirComponentes(index, this.data[0][index][1], 4, data2)
        });
      });
    } else {

      this.services.getPosgradoEntity(this.data[0][index][0]['route'], this.language).subscribe(data1 => {
        this.definirComponentes(index, this.data[0][index][0], 12, data1)
      });
    }



  }
  definirComponentes(index: number, element: any, width: number, data1: any) {

    let info = data1.data[0];
    if (element.entityId == 1) {
      this.pathMob = info.cover.pathMobile;
      this.path = info.cover.path;
      this.botones = info.coverButton;
      this.count++;
    }
    switch (element.entityType) {

      /* case "CustomSocialNetwork":
         this.newComponent(index, 'Cards', info, width)
 
         break;
 
       case "CustomPublication":
         this.newComponent(index, 'CustomPublication', info, width)
 
         break;
 
       case "TextItem":
         this.newComponent(index, 'TextItem', info, width)
 
         break;
 
       case 'EntityList':
         info.entityList.forEach(element => {
           let type = element.typeDisplay;
           var background = element.background;
           this.newComponent(index, type, info, width, background)
         });
 
         break;
       case "Diary":
         this.newComponent(index, 'Diary', info, width)
 
         break;*/
    }



  }
  newComponent(index: number, type: string, info: any, width: number, background?: string) {

    switch (type) {
      case 'Primera foto destacada': {
        let factory = this.componentFactoryResolver.resolveComponentFactory(PrimeraFotoDestacadaComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.data = info['entityList'][0].entity;
        ref.instance.title = info['entityList'][0].title;
        ref.changeDetectorRef.detectChanges();
      }

        break;
      case 'Simple': {
        let factory = this.componentFactoryResolver.resolveComponentFactory(CardsGeneralComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info.entityList[0].entity;
        ref.instance.title = info.entityList[0].title;
        ref.instance.background = background;
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.changeDetectorRef.detectChanges();

      }

        break;
      case 'CustomPublication': {
        let factory = this.componentFactoryResolver.resolveComponentFactory(CarrouselGeneralComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info.publication;
        ref.instance.title = 'Publicaciones';
        ref.instance.background = background;
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.changeDetectorRef.detectChanges();
      }

        break;
      case 'Noticias con resumen': {
        let factory = this.componentFactoryResolver.resolveComponentFactory(PostComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info.entityList[0].entity;
        ref.instance.title = info.entityList[0].title;
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.changeDetectorRef.detectChanges();
      }

        break;
      case 'Carrousel':
        if (info.entityList[0].entity) {
          let factory = this.componentFactoryResolver.resolveComponentFactory(CarrouselGeneralComponent);
          let ref = (this.viewContainerRefA(index)).createComponent(factory);
          ref.instance.data = info.entityList[0].entity;
          ref.instance.title = info.entityList[0].title;
          ref.instance.background = background;
          ref.location.nativeElement.classList.add('col-lg-' + width);
          ref.changeDetectorRef.detectChanges();
        }
        break;
      case 'Cards':
        let factory = this.componentFactoryResolver.resolveComponentFactory(CardsGeneralDosComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info.socialNetwork.socialNetworkItem;
        ref.instance.title = info.socialNetwork.title;
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.changeDetectorRef.detectChanges();
        break;
      case 'Lista de links':
        if (info.entityList[0].entity) {
          const factory = this.componentFactoryResolver.resolveComponentFactory(ListaDeLinksComponent);
          const ref = (this.viewContainerRefA(index)).createComponent(factory);
          ref.instance.data = info.entityList[0].entity;
          ref.instance.title = info.entityList[0].title;
          ref.location.nativeElement.classList.add('col-lg-' + width);
          ref.instance.background = background;
          ref.changeDetectorRef.detectChanges();
        }
        break;

      case 'Video + Formulario (en ingles)':
        if (info.entityList[0].linkCode) {
          const factory = this.componentFactoryResolver.resolveComponentFactory(VideoMasFormularioComponent
          );
          const ref = (this.viewContainerRefA(index)).createComponent(factory);
          ref.instance.linkCode = info.entityList[0].linkCode;
          ref.location.nativeElement.classList.add('col-lg-' + width);
          ref.instance.title = info.entityList[0].title;
          ref.instance.background = background;
          ref.instance.idioma = 'en';
          ref.changeDetectorRef.detectChanges();

        }
        break;
      case 'Video + Formulario (en español)':
        if (info.entityList[0].linkCode) {
          const factory = this.componentFactoryResolver.resolveComponentFactory(VideoMasFormularioComponent
          );
          const ref = (this.viewContainerRefA(index)).createComponent(factory);
          ref.instance.linkCode = info.entityList[0].linkCode;
          ref.location.nativeElement.classList.add('col-lg-' + width);
          ref.instance.title = info.entityList[0].title;
          ref.instance.background = background;
          ref.instance.idioma = 'es';
          ref.changeDetectorRef.detectChanges();

        }
        break;
      case 'TextItem': {
        const factory = this.componentFactoryResolver.resolveComponentFactory(TextItemGeneralComponent);
        const ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info.title;
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.i = index;
        ref.changeDetectorRef.detectChanges();

      }
        break;

      case 'CustomForm': {
        const factory = this.componentFactoryResolver.resolveComponentFactory(FormContactoComponent);
        const ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.changeDetectorRef.detectChanges();

      }
        break;
      case 'Diary': {
        const factory = this.componentFactoryResolver.resolveComponentFactory(CustomDiaryGeneralComponent);
        const ref = (this.viewContainerRefA(index)).createComponent(factory);
        if (index == 2) {
          width = 4;
        }
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.data = info.diary[0].diaryItem;
        ref.instance.title = info.diary[0].title;
        ref.instance.width = width;
        ref.changeDetectorRef.detectChanges();

      }
        break;
      case 'CustomStats': {
        const factory = this.componentFactoryResolver.resolveComponentFactory(CustomStatsGeneralComponent);
        const ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.data = info.CustomStats;
        ref.instance.width = width;
        ref.changeDetectorRef.detectChanges();

      }
        break;
      case 'Cabecera': {
        const factory = this.componentFactoryResolver.resolveComponentFactory(CabeceraComponent);
        const ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.title = info.entityList[0].title;
        ref.instance.slider = info.entityList[0].entity;
        ref.instance.background = info.entityList[0].background;
        ref.instance.path = info.entityList[0].path;
        ref.instance.width = width;
        ref.instance.noticesLinkTitle = info.entityList[0].noticesLinkTitle;
        ref.instance.noticesLink = info.entityList[0].noticesLink.systemTitle;
        ref.instance.cabeceraButtons = info.entityList[0].cabeceraButtons;
        ref.changeDetectorRef.detectChanges();

      }
        break;


    }






  }
  viewContainerRefA(index: number): any {
    switch (index) {
      case 1:
        return this.viewContainerRef1;
      case 2:
        return this.viewContainerRef2;
      case 3:
        return this.viewContainerRef3;
      case 4:
        return this.viewContainerRef4;
      case 5:
        return this.viewContainerRef5;
      case 6:
        return this.viewContainerRef6;
      case 7:
        return this.viewContainerRef7;
      case 8:
        return this.viewContainerRef8;
      case 9:
        return this.viewContainerRef9;
      case 10:
        return this.viewContainerRef10;
      case 11:
        return this.viewContainerRef11;
      case 12:
        return this.viewContainerRef12;
      default:
        // 
        break;
    }
  }

}

<section class="subheader  doble-m">
  <img onerror="this.src='./assets/img/default.png'"  class=" img-desk img-title w-100 desk img-desk" [src]="headerSection.path">
    <img onerror="this.src='./assets/img/default.png'"  class=" img-mob img-title w-100 mob img-mob" [src]="headerSection.pathMobile">
</section>


<section class="logsec1" id='elegi-tu-posgrado'>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <app-buscador-cursos></app-buscador-cursos>
            </div>
        </div>
    </div>
</section>

<section class="logsec1" >
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
            <div *ngIf='headerSection' id="text"></div>

            
          <section class="" id='elegi-tu-posgrado'>
              <div class="container">
                  <div class="row">
                      <div class="col-lg-12">
                          <app-cursos-proximos [educacionContinua]="true"></app-cursos-proximos>
                      </div>
                  </div>
              </div>
          </section>
       
        </div>
      </div>
    </div>
</section>
<section class="">
    <app-formas-de-contacto-dos [data]="'#5A8ABE'"></app-formas-de-contacto-dos>
</section>
import { Component, Input, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-areas-de-conocimiento',
  templateUrl: './areas-de-conocimiento.component.html',
  styleUrls: ['./areas-de-conocimiento.component.scss']
})
export class AreasDeConocimientoComponent implements OnInit {
  @Input() data: any;
  width = false;
  posgrados: any[] = [];

  constructor(private general: GeneralService) {
    this.general.getEntitylist('285', 'posgrados').subscribe(res => {
      res.data[0].entityList[0].entity.forEach(element => {
          element.class=element.title.replace(/ /g, "");
          this.posgrados.push(element);
      });

      this.posgrados.sort(this.SortArray);
    })
  }
  createLink(item) {
    let url = environment.apiUrl + item
    return url
  }
  ngOnInit(): void {


  }
  SortArray(x, y){
    if (x.title < y.title) {return -1;}
    if (x.title > y.title) {return 1;}
    return 0;
}
}

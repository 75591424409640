import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
declare var $: any;

@Component({
  selector: 'app-buscador-cursos',
  templateUrl: './buscador-cursos.component.html',
  styleUrls: ['./buscador-cursos.component.scss']
})
export class BuscadorCursosComponent implements OnInit {
  meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  regiones: string[] = [];
  cursos: any[] = [];
  niveles: any[] = [];
  areas: any[] = [];
  modalidades: any[] = [];
  tematicas: any[] = [];
  resultados: any[] = [];
  categorias: any[] = [];
  buscador: FormGroup;
  valor: string;
  count: number;
  pages: any[] = [];
  numberActive: number = 1;
  pageSize = 16;
  spinner = true;
  @Input() infoAreaRecomendada;
  filtro = false;
  language = "es"
  cursosAux: any[] = [];
  resultadosCompletos: any[] = [];
  auxBorrar: any[] = []
  destacadas = true;
  isDesktop: boolean;
  visiblePages: number = 5;
  totalPaginas: number;
  visiblePages2: (number | string)[] = [];
  @Output() busqueda = new EventEmitter<boolean>();
  @Input() data: any
  @Input() translateY;
  @Input() padding;
  @Input() paddingMob;
  constructor(public route: ActivatedRoute, private servicesGeneral: GeneralService, private form: FormBuilder) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });
    this.buscador = this.form.group({
      tematica: ['',],
      sede: ['',],
      nivel: ['',],
      modalidad: ['',],
      filtro: ['',],
      area: ['',],
      categoria: ['',]
    });


    this.filtrar(true)
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkDeviceType()
  }

  ngOnInit(): void {
    this.checkDeviceType()
  }

  updateFilter(field: string, value: any) {
    this.buscador.get(field).setValue(value);
    this.filtrar(null);
  }
  mostrarSede = true;

  checkMostrarSedes() {
    var modalidad = this.buscador.get('modalidad').value;
    console.log(modalidad)
    this.isDesktop = window.innerWidth >= 768;
    if (modalidad == "Virtual") {
      this.mostrarSede = false;
      this.buscador.controls['sede'].setValue(null);
      if (this.isDesktop) {
        $(".input-container").css("width", "28%");
        $(".input-container:first-child").css("width", "40%");
      } else {
        $(".input-container").css("width", "100%");
      }
    } else {
      this.mostrarSede = true;
      if (this.isDesktop) {
        $(".input-container").css("width", "20%");
        $(".input-container:first-child").css("width", "36.5%");
      } else {
        $(".input-container").css("width", "100%");
      }
    }
  }
  filtrar(event) {
    this.spinner = true;
    this.checkMostrarSedes()
    var categoria = this.buscador.get('categoria').value;
    var filtro = this.buscador.get('filtro').value;
    var nivel = this.buscador.get('nivel').value;
    var area = this.buscador.get('area')?.value;
    var modalidad = this.buscador.get('modalidad').value;
    var sede = this.buscador.get('sede').value;

    this.destacadas = sede == '' && nivel == '' && area == '' && filtro == '' && modalidad == '';
    this.servicesGeneral.getCursos(sede, nivel, filtro, null, modalidad, this.destacadas, area, categoria).subscribe(data => {
      let aux = [];
      this.resultados = [];
      this.cursos = [];
      if (event) {
        data.data[0].areaTematica.forEach(d => {
          this.areas.push({ title: d.description, id: d.id })
        })
        data.data[0].careerTypeCourse.forEach(n => {
          this.niveles.push(n)
        })
        this.regiones = data.data[0].region;
      }
      this.modalidades = data.data[0].modalidad;

      if (data.data[0].career) {
        aux = data.data[0].career;
        this.cursos = aux.sort(function (a, b) { return (a.fecha && b.fecha) ? a.fecha.getTime() - b.fecha.getTime() : 0 });
      }
      this.cambiarPagina(1)

    })
  }
  scroll = false;
  cambiarPagina(page) {

    this.pages = [];
    this.resultados = this.cursos.slice(((page - 1) * this.pageSize), (page) * this.pageSize)
    let cant = Math.ceil(this.cursos.length / this.pageSize)
    this.checkDeviceType()
    this.spaceBetweenElements();
    for (let i = 0; i < cant; i++) {
      this.pages.push(i + 1);
    }

    this.numberActive = page;
    this.updateVisiblePages();
    page = (page == '...') ? (this.numberActive) : page;
    // Hacer scroll al elemento con id 'resultados'
    const element = document.getElementById('buscador');
    if (this.scroll && element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    this.scroll = true;
    this.spinner = false;


  }

  spaceBetweenElements() {

    if (this.cursos.length == 0) {
      $(".fondo-area").css("margin-top", (this.isDesktop) ? "0px" : "-35px");
      $(".fondo-area").css("padding-top", (this.isDesktop) ? "30px" :"60px");

    } else {

      $(".fondo-area").css("margin-top", "-160px");
      $(".fondo-area").css("padding-top", "180px");
    }
  }
  primerPaginaHabilitada(): boolean {
    return this.numberActive > 1;
  }

  updateVisiblePages(): void {
    const totalPages = this.pages.length;
    const currentPage = this.numberActive;
    let startPage = 1;
    let endPage = Math.min(10, totalPages);
    if (totalPages > 10) {
      if (currentPage > 6) {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
        if (endPage > totalPages) {
          endPage = totalPages;
          startPage = endPage - 9;
        }
      }
    }
    this.visiblePages2 = [];
    for (let i = startPage; i <= endPage; i++) {
      this.visiblePages2.push(i);
    }

    if (startPage > 1) {
      if (startPage > 2) {
        this.visiblePages2.unshift('...');
      }
      this.visiblePages2.unshift(1);
    }
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        this.visiblePages2.push('...');
      }
      this.visiblePages2.push(totalPages);
    }
    if (this.pages.length > 1) {
      this.busqueda.emit(true);
    } else {
      this.busqueda.emit(false)
    }
  }

  checkDeviceType() {
    this.isDesktop = window.innerWidth >= 768;
    if (this.isDesktop) {
      $(".buscador-container").css("padding", this.padding);
      $(".buscador-container").css("transform", "translateY(-" + this.translateY + "px)");
      $(".resultados").css("margin-top", "-" + (this.translateY - 10) + "px");
      $(".resultados").css("padding-bottom", (this.resultados.length == 0) ? "5px" : "-90px");
    } else {
      $(".buscador-container").css("padding", this.paddingMob + "px");
      $(".buscador-container").css("transform", "translateY(-" + 0 + "px)");
      $(".resultados").css("margin-top", (this.resultados.length == 0) ? "0px" : "20px");
      $(".resultados").css("padding-bottom", (this.resultados.length == 0) ? "0px" : "-90px");

    }
    this.checkMostrarSedes();
  }

  ultimaPaginaHabilitada(): boolean {
    return this.numberActive < this.pages.length;
  }

  get rangoResultados(): string {
    const startIndex = (this.numberActive - 1) * this.pageSize + 1;
    const endIndex = Math.min(startIndex + this.pageSize - 1, this.cursos.length);
    return `${startIndex}-${endIndex} de ${this.cursos.length}`;
  }
  filtros(modalidad: string): any[] {
    let listaFiltrada = this.resultadosCompletos;
    if (modalidad) {
      listaFiltrada = listaFiltrada.filter(r => this.processText(r.modalidad) == this.processText(modalidad))
    }

    listaFiltrada.sort((a, b) => {
      const fechaA = new Date(a.fecha);
      const fechaB = new Date(b.fecha);

      const difA = Math.abs(Math.ceil((fechaA.getTime() - new Date().getTime()) / (1000 * 3600 * 24)));
      const difB = Math.abs(Math.ceil((fechaB.getTime() - new Date().getTime()) / (1000 * 3600 * 24)));

      if (difA < difB) {
        return -1;
      } else if (difA > difB) {
        return 1;
      } else {
        return fechaA.getTime() - fechaB.getTime();
      }
    });


    return listaFiltrada;
  }
  getInicio(mes: number, año: number) {
    return this.meses[mes] + ' ' + año;
  }
  processText(palabra: string): string {
    if (palabra) {
      palabra = palabra.trim();
      palabra = palabra.toLocaleLowerCase();
    }

    return palabra;
  }
  ngOnDestroy() {

  }
}

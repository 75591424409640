import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-buscador-cursos-tematico',
  templateUrl: './buscador-cursos-tematico.component.html',
  styleUrls: ['./buscador-cursos-tematico.component.scss']
})
export class BuscadorCursosTematicoComponent implements OnInit {
  meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  regiones: string[] = [];
  carreras: any
  id: any
  datos: any;
  cursos: any[] = [];
  niveles: any[] = [];
  areas: any[] = [];
  modalidades: any[] = [];
  categorias: any[] = [];
  tematicas: any[] = [];
  resultados: any[] = [];
  buscador: FormGroup;
  valor: string;
  count: number;
  pages: any[] = [];
  numberActive: number = 1;
  pageSize = 16;
  spinner = true;
  filtro = false;
  language = "es"
  cursosAux: any[] = [];
  resultadosCompletos: any[] = [];
  auxBorrar: any[] = []
  destacadas = true;
  carrerasArray: any[] = []
  carrerasAux: any
  title: any;

  @Input() data;
  @Input() area;
  @Input() titulo;
  @Input() translateY;
  @Input() padding;
  @Input() paddingMob;
  tys: boolean
  sinTys: boolean
  @Input() infoAreaRecomendada;
  visiblePages: number = 5;
  totalPaginas: number;
  visiblePages2: (number | string)[] = [];
  @Output() busqueda = new EventEmitter<boolean>();
  constructor(private aRouter: ActivatedRoute, private services: GeneralService, public route: ActivatedRoute, private form: FormBuilder) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });

    this.buscador = this.form.group({
      sede: ['',],
      area: ['',],
      nivel: ['',],
      modalidad: ['',],
      facultad: ['',],
      palabras: ['',],
      categoria: ['',],
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkDeviceType()
  }
  checkDeviceType() {
    let isDesktop = window.innerWidth >= 768;
    $(".buscador-container").css("background-color", this.data.background_color + "");
    if (isDesktop) {
      $(".buscador-container").css("padding", this.padding);
      $(".buscador-container").css("transform", "translateY(-" + this.translateY + "px)");
      $(".resultados").css("margin-top", "-" + (this.translateY - 10) + "px");
      $(".resultados").css("margin-bottom", (this.resultados.length == 0) ? 90 : 0 + "px");

    } else {
      $(".buscador-container").css("padding", this.paddingMob);
      $(".buscador-container").css("transform", "translateY(-" + 0 + "px)");
      $(".resultados").css("margin-top", "-" + 0 + "px");
      $(".resultados").css("margin-bottom", (this.resultados.length == 0) ? 120 : 70 + "px");

    }
    this.checkMostrarSedes();
  }

  ngOnInit(): void {
    this.filtrar();
    this.checkDeviceType()
  }

  updateFilter(field: string, value: any) {
    this.buscador.get(field).setValue(value);
    this.filtrar();
  }
  createLink(item) {
    let url = environment.apiUrl + item
    return url
  }


  mostrarSede = true;

  checkMostrarSedes() {
    var modalidad = this.buscador.get('modalidad').value;
    let isDesktop = window.innerWidth >= 768;
    if (modalidad == "Virtual") {
      this.mostrarSede = false;
      this.buscador.controls['sede'].setValue(null);
      if (isDesktop) {
        $(".input-container").css("width", "19.1%");
        $(".input-container:first-child").css("width", "36.5%");
      } else {
        $(".input-container").css("width", "100%");
        $(".input-container:first-child").css("width", "100%");

      }
    } else {
      this.mostrarSede = true;
      if (isDesktop) {
        $(".input-container").css("width", "14.1%");
        $(".input-container:first-child").css("width", "36.5%");
      } else {
        $(".input-container").css("width", "100%");
        $(".input-container:first-child").css("width", "100%");
      }
    }
  }
  filtrar() {
    this.spinner = true;
    var sede = this.buscador.get('sede').value;
    var nivel = this.buscador.get('nivel').value;
    var area = null
    var modalidad = this.buscador.get('modalidad').value;
    var palabras = this.buscador.get('palabras').value;
    var categoria = this.buscador.get('categoria').value;
    var resultados = document.getElementById("resultados")
    this.checkMostrarSedes()

    this.destacadas = sede == '' && nivel == '' && palabras == '' && modalidad == '';

    console.log(this.area)
    this.services.getCursos(sede, nivel, palabras, area, modalidad, false, this.area.id, categoria).subscribe(data => {
      this.resultados = [];
      this.regiones = data.data[0].region;
      this.areas = data.data[0].area;
      this.niveles = data.data[0].careerTypeCourse;
      this.modalidades = data.data[0].modalidad;
      this.categorias = []
      data.data[0].sub_categoria.forEach(d => {
        this.categorias.push({ id: d.id, title: d.name })
      })
      this.cursos = [];
      this.carrerasAux;
      if (data.data[0].career) {
        this.carrerasAux = data.data[0].career
        data.data[0].career.forEach(element => {
          this.cursos.push(element)

        });
      }

      this.cambiarPagina(1)

      if (sede == '' && nivel == '' && area == '' && modalidad == '' && palabras == '') {
        this.resultados = [];
        this.filtro = false
        resultados.style.display = 'none';
        this.spinner = false;

      } else {
        this.filtro = true
        this.spinner = false;
      }
    })
  }
  cortarTitulo(titulo) {
    if (titulo.length > 50) {
      return titulo.slice(0, 50) + "...";
    } else {
      return titulo;
    }
  }

  scroll=false;
  cambiarPagina(page) {
    this.pages = [];
    this.resultados = this.cursos.slice(((page - 1) * this.pageSize), (page) * this.pageSize)
    let cant = Math.ceil(this.cursos.length / this.pageSize)

    for (let i = 0; i < cant; i++) {
      this.pages.push(i + 1);
    }

    this.numberActive = page;
    this.updateVisiblePages();
    page = (page == '...') ? (this.numberActive) : page;
     // Hacer scroll al elemento con id 'resultados'
     const element = document.getElementById('busqueda');
     if (this.scroll && element) {
       element.scrollIntoView({ behavior: 'smooth', block: 'start' });
     }
     this.scroll = true;
    this.spinner = false;

  }
  primerPaginaHabilitada(): boolean {
    return this.numberActive > 1;
  }
  updateVisiblePages(): void {
    const totalPages = this.pages.length;
    const currentPage = this.numberActive;
    let startPage = 1;
    let endPage = Math.min(10, totalPages);
    if (totalPages > 10) {
      if (currentPage > 6) {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
        if (endPage > totalPages) {
          endPage = totalPages;
          startPage = endPage - 9;
        }
      }
    }
    this.visiblePages2 = [];
    for (let i = startPage; i <= endPage; i++) {
      this.visiblePages2.push(i);
    }

    if (startPage > 1) {
      if (startPage > 2) {
        this.visiblePages2.unshift('...');
      }
      this.visiblePages2.unshift(1);
    }
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        this.visiblePages2.push('...');
      }
      this.visiblePages2.push(totalPages);
    }
    if (this.pages.length > 1) {
      this.busqueda.emit(true);
    } else {
      this.busqueda.emit(false)
    }
  }

  ultimaPaginaHabilitada(): boolean {
    return this.numberActive < this.pages.length;
  }

  get rangoResultados(): string {
    const startIndex = (this.numberActive - 1) * this.pageSize + 1;
    const endIndex = Math.min(startIndex + this.pageSize - 1, this.carreras.length);
    return `${startIndex}-${endIndex} de ${this.carreras.length}`;
  }


  filtros(modalidad: string): any[] {
    let listaFiltrada = this.carrerasAux;
    if (modalidad) {
      listaFiltrada = listaFiltrada.filter(r => {
        if (r.modalidad) {
          return this.processText(r.modalidad) === this.processText(modalidad);
        }
      });
    }

    listaFiltrada.sort((a, b) => {
      const fechaA = new Date(a.fecha);
      const fechaB = new Date(b.fecha);

      const difA = Math.abs(Math.ceil((fechaA.getTime() - new Date().getTime()) / (1000 * 3600 * 24)));
      const difB = Math.abs(Math.ceil((fechaB.getTime() - new Date().getTime()) / (1000 * 3600 * 24)));

      if (difA < difB) {
        return -1;
      } else if (difA > difB) {
        return 1;
      } else {
        return fechaA.getTime() - fechaB.getTime();
      }
    });
    return listaFiltrada;
  }

  getInicio(mes: number, año: number): string {
    return this.meses[mes] + ' ' + año;
  }

  processText(palabra: string): string {
    palabra = palabra.trim();
    palabra = palabra.toLocaleLowerCase();

    return palabra;
  }

  formatearFecha(value: any): any {
    if (value) {
      const date = new Date(value);
      const monthNames = [
        "enero", "febrero", "marzo",
        "abril", "mayo", "junio", "julio",
        "agosto", "septiembre", "octubre",
        "noviembre", "diciembre"
      ];

      const day = date.getDate();
      const monthIndex = date.getMonth();
      const monthName = monthNames[monthIndex];

      return `${day} de ${monthName}`;
    } else {
      return 'consultar'
    }

  }

  url(element: any) {
    let url = '';
    let boolExternalLink: any = element.externalLink;
    if (!boolExternalLink) {
      if (element.route) {
        url = element.route
        if (element.route[0] == '/') {
          url = element.route = element.route.replace('/', '')
        }
        url = '/es/' + url;
      }
      if (element.systemTitle) {
        url = element.systemTitle
        if (element.systemTitle[0] == '/') {
          url = element.systemTitle = element.systemTitle.replace('/', '')
        }
        url = '/es/' + url;
      }
    } else {
      url = element.route;
    }

    return url;
  }
  eliminarPrefijoFacultad(cadena: string): string {
    const prefijo = 'Facultad de';
    if (cadena && cadena.startsWith(prefijo)) {
      return cadena.substring(prefijo.length).trim();
    } else {
      return cadena;
    }
  }
  ngOnDestroy() {

  }
}

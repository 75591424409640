import { Component, Input, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent {
  @Input() options: any[] = [];
  @Input() placeholder: string = 'Select';
  @Output() selectionChange = new EventEmitter<any>();
  @Input() ingreso: boolean = false
  @Input() id = '';

  selectedOption: any = null;
  dropdownOpen: boolean = false;
  hoverOption: any = null;
  constructor(private elementRef: ElementRef) { }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  selectOption(option) {
    this.selectedOption = option;
    this.dropdownOpen = false;
    this.selectionChange.emit(option.id || option);
    this.toggleDropdown()
  }
  @HostListener('document:click', ['$event'])
  onClickOutside(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.dropdownOpen = false;
    }
  }
}

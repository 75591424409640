<div class="custom-select-wrapper" *ngIf="!ingreso">
    <div class="custom-select" (click)="toggleDropdown()">
      <div [id]="id" class="custom-select-trigger nunito-medium "
      [ngClass]="{'black-text': selectedOption?.title || selectedOption}">
        {{ selectedOption?.title || selectedOption || placeholder }}
        <!-- <span class="arrow"><i class="fas fa-chevron-down" style="margin-right: 0px !important"></i></span> -->
        <span class="arrow"><img style="margin-right: 0px !important" src="../../../../assets/img/Flechita-dorada.png" alt=""></span>
      </div>
      <div class="custom-options " *ngIf="dropdownOpen">
        <span class="custom-option nunito-regular"
              [class.selected]="placeholder === selectedOption"
              (click)="selectOption('')"
              (mouseover)="hoverOption = placeholder"
              [class.hover]="placeholder === hoverOption">
          {{placeholder}}
        </span>
        <span class="custom-option nunito-regular"
              *ngFor="let item of options"
              [class.selected]="item === selectedOption"
              (click)="selectOption(item)"
              (mouseover)="hoverOption = item"
              [class.hover]="item === hoverOption">
              {{ item.title || item }}
        </span>
      </div>
    </div>
</div>
  
<div class="custom-select-wrapper" *ngIf="ingreso">
  <div class="custom-select" (click)="toggleDropdown()">
    <div  [id]="id"  class="custom-select-trigger trigger-i" 
    [ngClass]="{'blue-text': selectedOption?.title || selectedOption}">
      {{ selectedOption?.title || selectedOption || placeholder }}
      <!-- <span class="arrow"><i class="fas fa-chevron-down" style="margin-right: 0px !important"></i></span> -->
      <span class="arrow"><img style="margin-right: 0px !important" src="../../../../assets/img/flecha-select-azul.png" alt=""></span>
    </div>
    <div class="custom-options options-i nunito-regular" *ngIf="dropdownOpen">
      <span class="custom-option-i nunito-regular"
            [class.selected]="placeholder === selectedOption"
            (click)="selectOption('')"
            (mouseover)="hoverOption = placeholder"
            [class.hover]="placeholder === hoverOption">
        {{placeholder}}
      </span>
      <span class="custom-option-i nunito-regular"
            *ngFor="let item of options"
            [class.selected]="item === selectedOption"
            (click)="selectOption(item)"
            (mouseover)="hoverOption = item"
            [class.hover]="item === hoverOption">
            {{ item.title || item }}
      </span>
    </div>
  </div>
</div>
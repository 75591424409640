
<div class="posgrados-container" >
  <section class="subheader doble-m">
    <div class="overlay"></div>
    <img *ngIf="datos" onerror="this.src='./assets/img/default.png'" class=" w-100 desk" [src]="datos.path" >
    <img *ngIf="datos" onerror="this.src='./assets/img/default.png'" class=" w-100 mob" [src]="datos.pathMobile" >

    <img *ngIf="!isMob" class="w-100 banda-dorada " src="../../../../assets/img/banda-dorada.png" >
    <img *ngIf="isMob" class="w-100 banda-dorada " src="../../../../assets/img/Banda-dorad-mobile.png" >      
    <div style="position: relative; max-width: 1518px; margin: 0 auto;" class="contenedor">
        <div class="t-container-lg">
            <div class="t-container">
                <h1 class="titulo quicksand-regular">Carreras de Posgrado </h1>
                <h1 *ngIf="datos" class="subtitulo quicksand-medium">{{datos.title}}</h1> 

                <!--<div class="d-flex align-center">
                    <img onerror="this.src='./assets/img/default.png'" class="icon-title" [src]="createLink(datos.icono?.path)"> 
                    <h1 class="bold">{{datos.title}}</h1> 
                </div>-->
            </div> 
        </div> 
    </div>
</section>


<section class="agsec1" *ngIf='datos && datos.body'>
<div class="container">
  <div class="row">
    <div class="col-lg-12">
      <div id='body'></div>
    </div>
  </div>
</div>
</section>
<section class="" id='elegi-tu-posgrado' style="padding-top: 0px !important;">
<div class="">
    <div class="row">
        <div class="col-lg-12">
          <div style="text-align: -webkit-center;">
            <div style='padding: 2%; max-width: 1518px; text-align: left; ' class="buscador">
                <form [formGroup]="buscador" class="form-group" id="busqueda"
                    style='margin-bottom:0px;justify-content: space-between;'>
            
                    <div style="display: flex;
                    flex-wrap: wrap;  width: 100%;">
            
                        <div class="input-container">
                            <input #filtroInput autofocus type="text" formControlName='palabras' (keyup)="filtrar()" class="form-control-c nunito-medium "  placeholder="Palabras clave"  style="border-radius: 0px !important;     padding-left: 10px;">
                            <img class="buscar" src="../../../../../assets/img/buscar-gris.png" alt="">
                        </div>
        
                        
                        <!-- <select id="nivel" formControlName="nivel" class="form-control" (change)="filtrar()"  style="border-radius: 0px !important;" >
                            <option value=''>Nivel académico</option>
                            <option *ngFor='let item of niveles' [value]='item'>{{item}}</option>
                        </select> -->
                        <div class="input-container">
                          <app-custom-select [options]="niveles"
                              placeholder="Nivel académico"
                              (selectionChange)="updateFilter('nivel', $event)"
                              [id]="'select-nivel'">
                          </app-custom-select>
                        </div>
                        
                        <!-- <div class="input-container">
                          <input disabled type="text" class="form-control "  placeholder="Facultad de {{datos.title}}"  style="border-radius: 0px !important;">
                         
                      </div> -->
                        <!-- <select id="sedes" formControlName="sede" class="form-control " (change)="filtrar()"  style="border-radius: 0px !important;" >
                            <option value=''>Sede</option>
                            <option *ngFor='let item of regiones' [value]='item.id'>{{item.title}}</option>
                        </select> -->
                        <div class="input-container">
                            <app-custom-select [options]="regiones"
                              placeholder="Sede"
                              (selectionChange)="updateFilter('sede', $event)"
                              [id]="'select-sede'">
                          </app-custom-select>
                        </div>
                        
                        
                        <!-- <select id="venueId" formControlName="venueId" class="form-control" (change)="filtrar()" style="border-radius: 0px !important;">
                            <option value=''>Facultad</option>
                            <option *ngFor='let item of facultades' [value]='item.id'>{{item.title}}</option>
                        </select> -->
                        <div class="input-container">
                            <app-custom-select [options]="facultades"
                              placeholder="Facultad"
                              (selectionChange)="updateFilter('venueId', $event)"
                              [id]="'select-facultad'">
                          </app-custom-select>
                        </div>
                        
                        <!-- <select id="modalidad" formControlName="modalidad" class="form-control" (change)="filtrar()"  style="border-radius: 0px !important;" >
                            <option value=''>Modalidad</option>
                            <option *ngFor='let item of modalidades' [value]='item'>{{item}}</option>
                        </select> -->
                        <div class="input-container">
                          <app-custom-select [options]="modalidades"
                            placeholder="Modalidad"
                            (selectionChange)="updateFilter('modalidad', $event)"
                            [id]="'select-modalidad'">
                        </app-custom-select>
                      </div>
                      
            
                    </div>
                    <!-- <div class='text-center'>
                        <a (click)='filtrar()' class="btn btn-primary ml-2">Buscar</a>
            
                    </div> -->
            
            
                </form>
            </div>
            <div class="subtitle-p nunito-medium">
              <p  *ngIf="datos" [routerLink]="'/' + language +'/posgrados'" class="nunito-medium">Posgrado | <span class="nunito-bold">{{datos.title}}</span> </p>
            </div>
            <div class="resultados card p-2" id="resultados" >
                <ul *ngIf='!spinner' style="padding-left: 0px !important">
                  <div *ngFor="let nivel of carrerasArray">
                    <div style="display: flex;" class="title-container">
                      <div [style.text-align]="'center'" 
                      [style.border-bottom]="'3px solid ' + nivel.carreras.color"
                      [style.width]="'100%'"
                     
                      [style.margin]="'5px'"
                      [style.margin-top]="'0px'"
                      ></div>
                      <h2 style="min-width: max-content;" class="quicksand-regular">
                        {{ nivel.nivel }}
                      </h2>
                      <div [style.text-align]="'center'" 
                      [style.border-bottom]="'3px solid ' + nivel.carreras.color"
                      [style.width]="'100%'"
                     
                      [style.margin]="'5px'"
                      [style.margin-top]="'0px'"
                      ></div>
                    </div>
                  
                    <!-- <li class='mt-3 fs-21' *ngFor='let carrera of resultados'><a [href]="language +carrera.link"> {{carrera.nombre}} | {{carrera.modalidad}} </a></li> -->
                    <div class="sede-container">
                        <ng-container *ngFor="let carrera of nivel.carreras.carreras">
                          
                            <!-- <div class="sede" (click)="redirect(carrera)">
                                <div class="sede-overlay">
                                    <img *ngIf="carrera.pathMobile" [src]="carrera.pathMobile" alt="" class="img" onerror="this.src='../../../../assets/img/default.png'">
                                    <img *ngIf="!carrera.pathMobile" src="../../../../assets/img/default.png" alt="" class="img">
                                    <div class="overlay-text">
                                        <div>
                                            <p class="text text-small" style="display: flex; margin-bottom: 5px;" >{{carrera.modalidad}}
                                                <ng-container *ngIf="carrera.modalidad == 'Presencial'">
                                                    <img src="../../../../../assets/img/presencial-sm.png" alt="" class="icon-modalidad">
                                                </ng-container>
                                               
                                                <ng-container *ngIf="carrera.modalidad == 'Virtual'">
                                                    <img src="../../../../../assets/img/online-sm.png" alt="" class="icon-modalidad">
                                                </ng-container>
                                                <ng-container *ngIf="carrera.modalidad == 'Híbrida'" style="display: flex;">
                                                    <img src="../../../../../assets/img/presencial-sm.png" alt="" class="icon-modalidad" >
                                                    <img src="../../../../../assets/img/online-sm.png" alt="" class="icon-modalidad" >
                                                </ng-container>
                                            </p>
                                            <p class="text dorado" style="background-color: #C1B283; padding: 8px;  margin-bottom: 5px;     width: fit-content;">{{carrera.nivel}}</p>
                                            <p class="text text-small facultad" style="" [style.border-left]="'18px solid ' + carrera.color" *ngIf="carrera.facultades && carrera.facultades.length >0">{{carrera.facultades[0]?.title}}</p>
                                        </div>
                                        <div>
                                            <p class="text text-large">{{cortarTitulo(carrera.title) }}</p>
                                            <p class="text text-small"><span class="text-small-b">INICIO:</span> {{  formatearFecha(carrera.inicio?.date) }}</p>
                                            <p class="text text-small"><span class="text-small-b">SEDE:</span>  {{carrera.sede}}</p>
                                            <a [href]="language +carrera.linkCareer">
                                                <p  class="info text-small-b">MÁS INFO >></p>
                                            </a>
                                        </div>
                                        
                                    </div>
                                    
                                  </div>
                            </div> -->
                            <app-card-overlay [carrera]="carrera"></app-card-overlay>
                        </ng-container>
                    </div>
                    
                  </div>
                   
                </ul>
                <div class="d-flex justify-content-center" *ngIf='spinner && filtro'>
                    <div class="spinner-grow loading" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow loading" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow loading" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                <!-- <div class="paginacion mt-3" *ngIf='!spinner && resultados.length > 0'>
                    <div (click)='cambiarPagina(page)' *ngFor="let page of  pages" class="number "
                        [ngClass]='(numberActive == page)?"active":""'>{{page}}</div>
                </div> -->
                <div class="paginacion mt-3" *ngIf='!spinner && resultados.length > 0'>
                    <div class="range">{{ rangoResultados }}</div>
                    <button (click)="cambiarPagina(1)" class="number arrow" [disabled]="!primerPaginaHabilitada()" style="border: none; background: none;">|<</button>
                    <button (click)="cambiarPagina(numberActive - 1)" class="number arrow" [disabled]="!primerPaginaHabilitada()" style="border: none; background: none;">&lt;</button>
                    
                    <button (click)="cambiarPagina(numberActive + 1)" class="number arrow" [disabled]="!ultimaPaginaHabilitada()" style="border: none; background: none;">&gt;</button>
                    <button (click)="cambiarPagina(pages.length)" class="number arrow" [disabled]="!ultimaPaginaHabilitada()" style="border: none; background: none;">>|</button>
                  </div>
                  
                  
            
                <div *ngIf='carrerasArray && carrerasArray.length == 0 && !spinner && filtro'  class='p-3 text-center'>
                    <div class="alert alert-primary text-center" role="alert">
                        No hay resultados
                    </div>
                </div>
            </div>
        </div>
        
        </div>
    </div>
</div>
</section>

<section class="">

  <app-banner></app-banner>

</section>
<section class="">
    
  <app-formas-de-contacto-dos [data]="'#c1b283'"></app-formas-de-contacto-dos>

</section>

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { environment } from 'src/environments/environment';
import { Carrera } from '../../models/observacion';
import { FormContactoComponent } from 'src/app/modules/general/components/form-contacto/form-contacto.component';
import Swal from 'sweetalert2';
import { ConsultaRequest } from '../../models/consultaRequest';

@Component({
  selector: 'app-general-dos',
  templateUrl: './general-dos.component.html',
  styleUrls: ['./general-dos.component.scss']
})
export class GeneralDosComponent implements OnInit {
  //general con lista 
  datos: any;
  itemsParent: any[] = [];
  links: any[];
  titleList: string;
  styleColor = '#1e3e6f';
  language: any;
  meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  regiones: string[] = [];
  carreras: any
  niveles: Carrera[] = [];
  areas: Carrera[] = [];
  facultades: Carrera[] = [];
  modalidades: Carrera[] = [];
  resultados: Carrera[] = [];
  buscador: FormGroup;
  valor: string;
  count: number;
  pages: number[] = [];
  numberActive: number = 1;
  pageSize = 15;
  spinner = true;
  filtro = false;
  formDatos: FormGroup;
  type = "ingresa";
  carrer: string;
  venue: string;
  tel: Boolean = false;
  curso: any;
  dialog: any;
  carrerasArray: any
  carrerasAux: any
  id: any
  nivelPrioridad = ['Doctorado', 'Maestría', 'Especialización', 'Curso de Posgrado'];
  titulos = [
    { singular: 'Doctorado', plural: "Doctorados" },
    { singular: 'Maestría', plural: "Maestrías" },
    { singular: 'Especialización', plural: "Especializaciones" },
    { singular: 'Curso de Posgrado', plural: "Cursos de Posgrado" },
  ];
  isMob: boolean


  constructor(private aRouter: ActivatedRoute, private services: GeneralService, public route: ActivatedRoute, private form: FormBuilder) {
    this.aRouter.params.forEach((params: Params) => {
      this.language = params['language'];
    });


    var url = window.location.href;
    var id = url.replace(environment.frontUrl + '/' + this.language + '/', '');
    var gclid = id.indexOf('?')
    var busqueda = (gclid > 0) ? id.slice(0, gclid) : id;
    this.buscador = this.form.group({
      sede: ['',],
      area: ['',],
      nivel: ['',],
      modalidad: ['',],
      facultad: ['',],
      palabras: ['',],
      venueId: ['',],
    });
    this.formDatos = this.form.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.email, Validators.required]],
      region: ['', [Validators.required]],
      //phone: ['', [Validators.pattern(Patters.OnlyNumber)]],
      message: [''],
    });
    this.checkScreenWidth()

    this.services.getPosgrados().subscribe(data => {
      this.resultados = [];
      this.carreras = [];


      //this.regiones = data.data[0].region;
      this.areas = data.data[0].area;
      this.getFacultadID()

      data.data[0].careerType.forEach(n => {
        if (n != 'Posgrado Online' && n != 'Curso Online' && n != 'Postítulo' && n != 'Posgrado Online' && n != 'Curso de Posgrado Online') {
          this.niveles.push(n)
        }
      });
      //this.niveles = data.data[0].careerType;
      this.modalidades = data.data[0].modalidad;
      this.spinner = false;

    })


    this.services.getSimplepage(busqueda, this.language).subscribe(data => {
      if (data.data[0]) {
        this.datos = data.data[0];
        this.id = this.datos.title
        if (this.datos.parent && this.datos.parent[0].children) {
          this.datos.parent[0].children.forEach(element => {
            this.itemsParent.push(element)
          });
        }
        if (this.datos.children) {
          this.datos.children.forEach(element => {
            this.itemsParent.push(element)
          });
        }

        if (this.datos.headerSection) {
          this.datos.headerSection.headerSection.headerSectionItem.forEach(element => {
            this.itemsParent.push(element)
          });
        }
        this.filtrar()
        if (this.datos.links) {
          this.styleColor = this.datos.links[0].styleColor;
          this.titleList = this.datos.links[0].title;
          //this.links = this.datos.links
        }

      }
      this.services.getFacultadByArea(this.id).subscribe(data => {
        data.data[0].venue.forEach(facultadad => {
          facultadad.title = this.eliminarPrefijoFacultad(facultadad.title);
          this.facultades.unshift(facultadad)
        })

        data.data[0].region.forEach(sede => {
          sede.title = this.eliminarPrefijoFacultad(sede.title);
          this.regiones.unshift(sede)
        })
      })
    })



  }

  private checkScreenWidth() {
    const evaluateScreenWidth = () => {
      if (window.innerWidth <= 768) {
        this.isMob = true;
      } else {
        this.isMob = false;
      }
    };
    evaluateScreenWidth();
    window.addEventListener('resize', evaluateScreenWidth);
  }
  createLink(item) {
    let url = environment.apiUrl + item
    return url
  }
  redirect(c) {
    if (c.route) {
      window.location.href = this.language + c.route
    } else if (c.linkCareer) {
      window.location.href = this.language + c.linkCareer
    }

  }
  getFacultadID() {

    /* this.facultades.forEach(fac => {
      if(fac.title == "Facultad de "+this.datos.title){
        this.id = fac.id
      }
    }) */
  }
  getCarrerasKeys(): string[] {
    return Object.keys(this.carreras);
  }

  consultar() {
    let datos = new ConsultaRequest();
    datos.email = this.formDatos.get('email').value;
    datos.name = this.formDatos.get('name').value;
    datos.region = this.formDatos.get('region').value;
    datos.isHome = false;
    datos.message = this.formDatos.get('message').value;
    datos.career = this.carrer;
    // datos.phone = this.formDatos.get('phone').value;
    //datos.curso = this.curso;
    //datos.venue = this.venue;
    if (datos.venue && datos.career) {
      this.consultarEmblue(datos);
    }
    this.services.postConsulta(datos).then(function (response) {
      return response.json();
    }).then(res => {
      if (res.status == 1) {
        this.formDatos.reset()
        Swal.fire(
          'La consulta fue enviada con exito!',
          '',
          'success'
        )
      } else {
        Swal.fire(
          'La consulta fallo , lo sentimos',
          '',
          'error'
        )

      }
    }
    )


  }
  consultarEmblue(datos: ConsultaRequest) {
    let atributos = (
      {
        career: carrera,
        name: nombre,
        phone: whatsapp,
        region: sede
      }
    ) => ({ carrera, nombre, whatsapp, sede });
    let eventoEmblue = {
      eventName: 'form_derecho',
      email: datos.email,
      attributes: atributos(datos)
    };
    if (datos.venue) {
      switch (datos.venue) {
        case "facultad-de-derecho":
          eventoEmblue.eventName = "form_derecho"
          break;
        case "facultad-de-psicologia-y-psicopedagogia":
          eventoEmblue.eventName = "form_psico"
          break;
        case "facultad-de-ciencias-medicas":
          eventoEmblue.eventName = "form_cs.medicas"
          break;
        case "facultad-de-ingenieria-y-ciencias-agrarias":
          eventoEmblue.eventName = "form_ingeniería"
          break;
        case "facultad-de-artes-y-ciencias-musicales":
          eventoEmblue.eventName = "form_musica"
          break;
        case "facultad-de-filosofia-y-letras":
          eventoEmblue.eventName = "form_filo"
          break;
        case "facultad-de-ciencias-economicas":
          eventoEmblue.eventName = "form_economicas"
          break;
        case "facultad-de-ciencias-sociales":
          eventoEmblue.eventName = "form_sociales"
          break;

        default:
          eventoEmblue.eventName = "form_derecho"
          break;
      }
    }

    if (this.type == "posgrados") {
      this.services.postEmblueFormPosgrados(eventoEmblue).subscribe(
        (data) => console.log({ message: ' Form emblue Posgrados', data: data }),
        error => console.log({ message: ' Form emblue Posgrados', error: error })
      )
    } else {
      this.services.postEmblueForm(eventoEmblue).subscribe(
        (data) => console.log({ message: 'Form emblue ', data: data }),
        error => console.log({ message: ' Form emblue', error: error })
      )
    }

  }
  openModal(): void {
    let dialogRef = this.dialog.open(FormContactoComponent, { panelClass: 'custom-dialog-container', data: { curso: null } });
    const sub = dialogRef.componentInstance.closeModal.subscribe(() => {
      this.dialog.closeAll();
    });
  }

  windowOpen(url) {
    window.open(url)
  }
  updateFilter(field: string, value: any) {
    this.buscador.get(field).setValue(value);
    this.filtrar();
  }
  filtrar() {
    this.spinner = true;
    var sede = this.buscador.get('sede').value;
    var nivel = this.buscador.get('nivel').value;
    var area = this.id
    var modalidad = this.buscador.get('modalidad').value;
    var palabras = this.buscador.get('palabras').value;
    var venue = this.buscador.get('venueId').value;
    var resultados = document.getElementById("resultados")
    /*  var destac = document.getElementById("destac"); */

    this.services.getPosgrados(sede, nivel, area, palabras, null, modalidad, venue).subscribe(data => {
      let aux = [];
      this.resultados = [];
      this.carreras = [];
      if (data.data[0].career) {

        this.carrerasAux = data.data[0].career
        let carrerasOriginales: any

        carrerasOriginales = this.carrerasAux
        const carrerasAgrupadas: { [nivel: string]: { carreras: Carrera[], color: string } } = {};

        carrerasOriginales.forEach(carrera => {
          const nivel = carrera.nivel;
          if (nivel == 'Curso de Posgrado' || nivel == 'Doctorado' || nivel == 'Maestría' || nivel == 'Especialización') {
            if (!carrerasAgrupadas[nivel]) {
              carrerasAgrupadas[nivel] = { carreras: [], color: carrera.color };
            }
            carrerasAgrupadas[nivel].carreras.push(carrera);
          }
        });

        this.carreras = carrerasAgrupadas;

        this.carrerasArray = Object.entries(this.carreras).map(([nivel, carreras]) => ({ nivel, carreras }));
        this.carrerasArray.sort((a, b) => {
          return this.nivelPrioridad.indexOf(a.nivel) - this.nivelPrioridad.indexOf(b.nivel);
        });
        //asignar titulos pliral o singular
        this.nivelPrioridad.forEach(nombreNivel => {
          let nivel = this.carrerasArray.find(d => d.nivel == nombreNivel);
          let carreras = nivel?.carreras?.carreras;
          if (carreras?.length > 1) {
            let titulo = this.titulos.find(d => d.singular == nombreNivel)
            nivel.nivel = titulo.plural;
          }
        })


      }
      if (sede == '' && nivel == '' && area == '' && modalidad == '' && palabras == '') {
        this.resultados = [];
        this.filtro = false
        resultados.style.display = 'none';

      } else {
        this.filtro = true
        if (resultados) resultados.style.display = 'block';
        this.spinner = false;
      }
    })
  }
  cortarTitulo(titulo) {
    if (titulo.length > 50) {
      return titulo.slice(0, 50) + "...";
    } else {
      return titulo;
    }
  }
  cambiarPagina(page) {
    var modalidad = this.buscador.get('modalidad').value;
    var palabra = this.buscador.get('palabras').value;

    this.pages = [];
    let resultadoFiltrado = this.filtros(modalidad);
    this.resultados = resultadoFiltrado.slice(((page - 1) * this.pageSize), (page) * this.pageSize)
    let cant = Math.ceil(this.carreras.length / this.pageSize)

    for (let i = 0; i < cant; i++) {
      this.pages.push(i + 1);
    }

    this.numberActive = page;
    page = (page == '...') ? (this.numberActive) : page;
    this.spinner = false;

  }
  primerPaginaHabilitada(): boolean {
    return this.numberActive > 1;
  }

  ultimaPaginaHabilitada(): boolean {
    return this.numberActive < this.pages.length;
  }

  get rangoResultados(): string {
    const startIndex = (this.numberActive - 1) * this.pageSize + 1;
    const endIndex = Math.min(startIndex + this.pageSize - 1, this.carreras.length);
    return `${startIndex}-${endIndex} de ${this.carreras.length}`;
  }


  filtros(modalidad: string): any[] {
    let listaFiltrada = this.carrerasAux;
    if (modalidad) {
      listaFiltrada = listaFiltrada.filter(r => {
        if (r.modalidad) {
          return this.processText(r.modalidad) === this.processText(modalidad);
        } /* else {
          return true; 
        } */
      });
    }

    listaFiltrada.sort((a, b) => {
      const fechaA = new Date(a.fecha);
      const fechaB = new Date(b.fecha);

      const difA = Math.abs(Math.ceil((fechaA.getTime() - new Date().getTime()) / (1000 * 3600 * 24)));
      const difB = Math.abs(Math.ceil((fechaB.getTime() - new Date().getTime()) / (1000 * 3600 * 24)));

      if (difA < difB) {
        return -1;
      } else if (difA > difB) {
        return 1;
      } else {
        return fechaA.getTime() - fechaB.getTime();
      }
    });
    return listaFiltrada;
  }

  getInicio(mes: number, año: number): string {
    return this.meses[mes] + ' ' + año;
  }

  processText(palabra: string): string {
    palabra = palabra.trim();
    palabra = palabra.toLocaleLowerCase();

    return palabra;
  }

  formatearFecha(value: any): any {
    if (value) {
      const date = new Date(value);
      const monthNames = [
        "enero", "febrero", "marzo",
        "abril", "mayo", "junio", "julio",
        "agosto", "septiembre", "octubre",
        "noviembre", "diciembre"
      ];

      const day = date.getDate();
      const monthIndex = date.getMonth();
      const monthName = monthNames[monthIndex];

      return `${day} de ${monthName}`;
    } else {
      return 'a confirmar'
    }

  }
  ngOnInit(): void {
  }
  url(element: any) {
    let url = '';
    let boolExternalLink: any = element.externalLink;
    if (!boolExternalLink) {
      if (element.route) {
        url = element.route
        if (element.route[0] == '/') {
          url = element.route = element.route.replace('/', '')
        }
        url = '/es/' + url;
      }
      if (element.systemTitle) {
        url = element.systemTitle
        if (element.systemTitle[0] == '/') {
          url = element.systemTitle = element.systemTitle.replace('/', '')
        }
        url = '/es/' + url;
      }
    } else {
      url = element.route;
    }

    return url;
  }
  eliminarPrefijoFacultad(cadena: string): string {
    const prefijo = 'Facultad de';
    if (cadena && cadena.startsWith(prefijo)) {
      return cadena.substring(prefijo.length).trim();
    } else {
      return cadena;
    }
  }
}

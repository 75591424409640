
    <div class="" style="text-align: -webkit-center;   ">
        <div class="content" style="    justify-content: center;          ">
           
                <div class="posg-cont">
                    <!-- <a [href]="'/es/'+item.systemTitle" *ngFor='let item of posgrados;index as i'
                        [ngClass]="(posgrados.length>1)?'posg-item '+item.class :'posg-item'">
                        <div>{{item.title}}</div>
                    </a> -->
                    <a [href]="'/es/'+item.systemTitle" *ngFor='let item of posgrados;index as i'
                        class="item">
                        <div style="display: flex; align-items: center;
                        gap: 30px;" class="area">
                           
                                <img *ngIf="item.icono"  [src]="createLink(item.icono)" alt="" class="img"  alt="" class="img"> 
                           
                            <div *ngIf="!item.icono" style=" width: 57px;
                            height: 57px;"></div>
                            <p class="title quicksand-regular">
                                {{item.title}}
                            </p>
                           </div>
                    </a>
                </div>
           
        </div>
    </div>

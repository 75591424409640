<div style="text-align: -webkit-center;">
    <div class="container2">
        <div class="container-izq" *ngIf="!isMob">
            <img src="../../../../../assets/img/hand-tounching-laptop-many-envelopes-opt.jpg" alt="" class="img-izq">
            <!-- <iframe 
            width="248" 
            height="248"
            src="https://www.youtube.com/embed/7IaPSDINYso" 
            title="UCA mucho más de lo que imaginás" 
            frameborder="0" 
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowfullscreen carouselvideo>
            </iframe> -->
        </div>
        <div  class="container-der"  [style.background-color]=" data">
            <h5 class="contactate quicksand-regular">Contactate con nosotros</h5>
            <div class="grey" >
                <a style="   
                margin-bottom: 0px; color: white; align-content: center;" class="text-m nunito-regular" href="https://api.whatsapp.com/send?phone=5491130182402&app=facebook&entry_point=page_cta" target="_blank">ENVIANOS UN WHATSAPP</a>
                <img src="../../../../../assets/img/whsatsapp-blanco.png" alt="" class="wpp-icon">
            </div>
            <p style="color: white;margin-top:10px" class="text-small quicksand-medium">o dejanos tus datos para recibir más información</p>
            <form [formGroup]="formDatos" action="">
                <!-- <div *ngIf="formDatos.get('name').hasError('required') && formDatos.get('name').touched || formDatos.get('email').hasError('required') && formDatos.get('email').touched"
                        class="text-left">
                        <span class="requerido quicksand-medium">*Estos campos son requeridos </span>
                    </div> -->
                <div class="nombre-email">
                    <input type="text" placeholder="Nombre" class="input quicksand-medium"   formControlName='name'  matTooltip="Este campo es obligatorio." matTooltipPosition="above" matTooltipClass="custom-tooltip" #nameTooltip="matTooltip" >
                    <input type="text" placeholder="E-mail" class="input quicksand-medium" formControlName='email' matTooltip="Este campo es obligatorio y debe ser un mail" matTooltipPosition="above" matTooltipClass="custom-tooltip" #emailTooltip="matTooltip">
                </div>
                <!-- <select formControlName='region' class="input quicksand-medium" matTooltip="Seleccione una sede." matTooltipPosition="above" matTooltipClass="custom-tooltip">
                    <option value=""  >Sede</option>
                    <option value="Buenos Aires">Buenos Aires</option>
                    <option value="Mendoza">Mendoza</option>
                    <option value="Paraná">Paraná</option>
                    <option value="Rosario">Rosario</option>
                </select> -->
                <div class="input quicksand-medium" style="padding: 0px !important; text-align: left;" matTooltip="Seleccione una sede." matTooltipPosition="above" matTooltipClass="custom-tooltip"  #regionTooltip="matTooltip">
                    <app-custom-select [options]="sedes"
                        placeholder="Sede"
                        (selectionChange)="updateFilter('region', $event)">
                    </app-custom-select>
                </div>
                <textarea formControlName='message' name="" id="" cols="30" rows="3" placeholder="Mensaje" class="input quicksand-medium"></textarea>
                <div class="redes2">
                    <div style="display: flex; align-items: center;">
                        <p style="margin-bottom: 0px;" class="text-small nunito-medium">Seguinos en redes</p>
                        <a style='cursor:pointer;color: #007bff;' (click)='windowOpen("https://www.facebook.com/UCAPosgrados")'>
                            <img src="../../../../../assets/img/fb-posgrado.png" alt="" class="img-icon">
                        </a>
                        <a href="" style='cursor:pointer;color: #007bff;' (click)='windowOpen("https://www.instagram.com/posgradosuca")'>
                            <img src="../../../../../assets/img/ig-posgrado.png" alt="" class="img-icon">
                        </a>
                    </div>
                    
                    <button (click)='consultar()'  class="btn nunito-regular" >ENVIAR</button>
                </div>
            </form>
            
        </div>
    </div>
</div>


<!-- <div class="container">
    <div class="row">
        <div class="col-lg-4 ">
            <h2 class='azul'>Seguinos en redes</h2>
            <h3 class='grey'>
                <a style='cursor:pointer;color: #007bff;' (click)='windowOpen("https://www.instagram.com/posgradosuca")'>
                    <img onerror="this.src='./assets/img/default.png'" class='icon' src="../../../../../assets/img/instagram-logo-circle.png" alt="">
                    /posgradosuca
                </a>
            </h3>

            <h3 class='grey'>
                <a style='cursor:pointer;color: #007bff;' (click)='windowOpen("https://www.facebook.com/UCAPosgrados")'>
                    <img onerror="this.src='./assets/img/default.png'" class='icon' src="../../../../../assets/img/facebook-circle.png" alt="">
                    /UCAPosgrados
                </a>
            </h3>


        </div>
        <div class="col-lg-4 ">
            <h2 class='azul' style='margin-bottom:5px;'>Descarga la app</h2>
            <div style='display: flex;' class='text-center'>
                <div class='w-40 text-center '>
                    <img style='width: 90%;' onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/qr-appStore.png" alt="">
                    <img style='width: 80%;' onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/app-store.png"
                        alt="">

                </div>
                <div class='w-40 text-center '>
                    <img style='width: 90%;' onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/qr-googlePlay.png" alt="">
                    <img style='width: 80%;' onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/google-play.png"
                        alt="">
                </div>

            </div>

        </div>
        <div class="col-lg-4 text-center">
            <h2 class='azul'>Contactate con nosotros</h2>
            <a (click)='openModal()' class="btn btn-primary btn-contacto">Contacto</a>

        </div>
    </div>
</div> -->

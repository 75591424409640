<div class="sede height-posgrado" (click)="redirect(carrera)">
  <div class="sede-overlay">
      <img *ngIf="carrera.pathMobile" [src]="carrera.pathMobile" alt="" class="img" onerror="this.src='../../../../assets/img/default.png'">
      <img *ngIf="!carrera.pathMobile" src="../../../../assets/img/default.png" alt="" class="img">
      <div class="overlay-text">
          <div>
              <p class="text text-small nunito-regular" style="display: flex; margin-bottom: 5px;" >{{carrera.modalidad}}
                  <ng-container *ngIf="carrera.modalidad == 'Presencial'">
                      <img src="../../../../../assets/img/presencial-sm.png" alt="" class="icon-modalidad">
                  </ng-container>
                 
                  <ng-container *ngIf="carrera.modalidad == 'Virtual'">
                      <img src="../../../../../assets/img/online-sm.png" alt="" class="icon-modalidad">
                  </ng-container>
                  <ng-container *ngIf="carrera.modalidad == 'Híbrida'" style="display: flex;">
                      <img src="../../../../../assets/img/presencial-sm.png" alt="" class="icon-modalidad" >
                      <img src="../../../../../assets/img/online-sm.png" alt="" class="icon-modalidad" >
                  </ng-container>
              </p>
              <p class="text dorado quicksand-regular" style="background-color: #C1B283; padding: 8px;  margin-bottom: 5px;     width: fit-content;">{{carrera.nivel}}</p>
              <p class="text text-small  nunito-regular facultad" style="" [style.border-left]="'18px solid ' + carrera.color" *ngIf="carrera.facultades && carrera.facultades.length >0">{{carrera.facultades[0]?.title}}</p>
          </div>
          <div>
              <p class="text text-large quicksand-medium">{{cortarTitulo(carrera.title) }}</p>
              <p class="text text-small nunito-regular"><span class="nunito-bold">INICIO:</span> {{  formatearFecha(carrera) }}</p>
              <p class="text text-small nunito-regular"><span class="nunito-bold">SEDE:</span>  {{carrera.sede}}</p>
              <a [href]="language +carrera.linkCareer">
                  <p  class="info nunito-regular">MÁS INFO >></p>
              </a>
          </div>
          
      </div>
      
    </div>
</div>
